export const mpuWhenNoEpic = {
    id: 'MpuWhenNoEpic',
    author: '@commercial-dev',
    start: '2023-11-22',
    expiry: '2024-03-29',
    audience: 0 / 100,
    audienceOffset: 5 / 100,
    audienceCriteria: '',
    successMeasure: '',
    description: 'Test MPU when there is no epic at the end of Article on the page.',
    variants: [
        {
            id: 'control',
            test: () => {
                /* no-op */
            },
        },
        {
            id: 'variant',
            test: () => {
                /* no-op */
            },
        },
    ],
    canRun: () => true,
};
