import { isString } from '@guardian/libs';
import { refreshAdvert } from '../../display/load-advert';
import { getAdvertById } from '../../lib/dfp/get-advert-by-id';
const passbackRefresh = (specs, adSlot) => {
    const advert = getAdvertById(adSlot.id);
    if (advert) {
        advert.slot.setTargeting('passback', specs);
        refreshAdvert(advert);
    }
};
const init = (register) => {
    register('passback-refresh', (specs, _, iframe) => {
        if (iframe && isString(specs)) {
            const adSlot = iframe.closest('.js-ad-slot') ?? undefined;
            if (!adSlot) {
                return;
            }
            return passbackRefresh(specs, adSlot);
        }
    });
};
export const _ = { passbackRefresh };
export { init };
