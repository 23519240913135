import { onConsent } from '@guardian/consent-management-platform';
import { getMeasures, isNonNullable, log } from '@guardian/libs';
import { EventTimer } from './event-timer';
var Endpoints;
(function (Endpoints) {
    Endpoints["CODE"] = "//performance-events.code.dev-guardianapis.com/commercial-metrics";
    Endpoints["PROD"] = "//performance-events.guardianapis.com/commercial-metrics";
})(Endpoints || (Endpoints = {}));
let commercialMetricsPayload = {
    page_view_id: undefined,
    browser_id: undefined,
    platform: 'NEXT_GEN',
    metrics: [],
    properties: [],
};
let devProperties = [];
let adBlockerProperties = [];
let initialised = false;
let endpoint;
const setEndpoint = (isDev) => (endpoint = isDev ? Endpoints.CODE : Endpoints.PROD);
const setDevProperties = (isDev) => (devProperties = isDev
    ? [{ name: 'isDev', value: window.location.hostname }]
    : []);
const setAdBlockerProperties = (adBlockerInUse) => {
    adBlockerProperties =
        adBlockerInUse !== undefined
            ? [
                {
                    name: 'adBlockerInUse',
                    value: adBlockerInUse.toString(),
                },
            ]
            : [];
};
const transformToObjectEntries = (eventTimerProperties) => {
    // Transforms object {key: value} pairs into an array of [key, value] arrays
    return Object.entries(eventTimerProperties);
};
const mapEventTimerPropertiesToString = (properties) => {
    return properties.map(([name, value]) => ({
        name: String(name),
        value: String(value),
    }));
};
const roundTimeStamp = (events, measures) => {
    const roundedEvents = events.map(({ name, ts }) => ({
        name,
        value: Math.ceil(ts),
    }));
    const roundedMeasures = measures.map(({ name, duration }) => ({
        name,
        value: Math.ceil(duration),
    }));
    return [...roundedEvents, ...roundedMeasures];
};
function sendMetrics() {
    log('commercial', 'About to send commercial metrics', commercialMetricsPayload);
    return navigator.sendBeacon(endpoint, JSON.stringify(commercialMetricsPayload));
}
/**
 * Gather how many times the user has experienced the “offline” event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/offline_event
 *
 * This value should be fetched as late as possible in the page lifecycle,
 * to get an accurate value.
 *
 * Relevant for an @guardian/open-journalism investigation.
 */
const getOfflineCount = () => typeof window.guardian.offlineCount === 'number'
    ? [
        {
            name: 'offlineCount',
            value: window.guardian.offlineCount,
        },
    ]
    : [];
/**
 * Measures added with @guardian/libs’s `startPerformanceMeasure`
 *
 * Allows for more granular monitoring of web page performance.
 */
const getPerformanceMeasures = (...teams) => getMeasures(teams).map(({ detail: { subscription, name, action }, duration }) => ({
    name: [subscription, name, action].filter(isNonNullable).join('_'),
    value: duration,
}));
function gatherMetricsOnPageUnload() {
    // Assemble commercial properties and metrics
    const eventTimer = EventTimer.get();
    const transformedEntries = transformToObjectEntries(eventTimer.properties);
    const filteredEventTimerProperties = transformedEntries.filter((item) => typeof item[1] !== 'undefined');
    const mappedEventTimerProperties = mapEventTimerPropertiesToString(filteredEventTimerProperties);
    const properties = mappedEventTimerProperties
        .concat(devProperties)
        .concat(adBlockerProperties);
    commercialMetricsPayload.properties = properties;
    const metrics = roundTimeStamp(eventTimer.marks, eventTimer.measures)
        .concat(getOfflineCount())
        .concat(getPerformanceMeasures('dotcom'));
    commercialMetricsPayload.metrics = metrics;
    sendMetrics();
}
const listener = (e) => {
    switch (e.type) {
        case 'visibilitychange':
            if (document.visibilityState === 'hidden') {
                gatherMetricsOnPageUnload();
            }
            return;
        case 'pagehide':
            gatherMetricsOnPageUnload();
            return;
    }
};
const addVisibilityListeners = () => {
    // Report all available metrics when the page is unloaded or in background.
    window.addEventListener('visibilitychange', listener, { once: true });
    // Safari does not reliably fire the `visibilitychange` on page unload.
    window.addEventListener('pagehide', listener, { once: true });
};
const checkConsent = async () => {
    const consentState = await onConsent();
    if (consentState.tcfv2) {
        // TCFv2 mode - check for consent
        const consents = consentState.tcfv2.consents;
        const REQUIRED_CONSENTS = [7, 8];
        return REQUIRED_CONSENTS.every((consent) => consents[consent]);
    }
    // non-TCFv2 mode - don't check for consent
    return true;
};
/**
 * A method to asynchronously send metrics after initialization.
 */
async function bypassCommercialMetricsSampling() {
    if (!initialised) {
        console.warn('initCommercialMetrics not yet initialised');
        return;
    }
    const consented = await checkConsent();
    if (consented) {
        addVisibilityListeners();
    }
    else {
        log('commercial', "Metrics won't be sent because consent wasn't given");
    }
}
/**
 * A method to initialise metrics.
 * Note: this is initialised in the frontend/DCR bundles, not the commercial bundle.
 * @param init.pageViewId - identifies the page view. Usually available on `guardian.config.ophan.pageViewId`. Defaults to `null`
 * @param init.browserId - identifies the browser. Usually available via `getCookie({ name: 'bwid' })`. Defaults to `null`
 * @param init.isDev - used to determine whether to use CODE or PROD endpoints.
 * @param init.adBlockerInUse - indicates whether or not an adblocker is being used.
 * @param init.sampling - rate at which to sample commercial metrics - the default is to send for 1% of pageviews
 */
async function initCommercialMetrics({ pageViewId, browserId, isDev, adBlockerInUse, sampling = 1 / 100, }) {
    commercialMetricsPayload.page_view_id = pageViewId;
    commercialMetricsPayload.browser_id = browserId;
    setEndpoint(isDev);
    setDevProperties(isDev);
    setAdBlockerProperties(adBlockerInUse);
    if (initialised) {
        return false;
    }
    initialised = true;
    const userIsInSamplingGroup = Math.random() <= sampling;
    if (isDev || userIsInSamplingGroup) {
        const consented = await checkConsent();
        if (consented) {
            addVisibilityListeners();
            return true;
        }
        log('commercial', "Metrics won't be sent because consent wasn't given");
    }
    return false;
}
export const _ = {
    Endpoints,
    setEndpoint,
    mapEventTimerPropertiesToString,
    roundTimeStamp,
    transformToObjectEntries,
    reset: () => {
        initialised = false;
        commercialMetricsPayload = {
            page_view_id: undefined,
            browser_id: undefined,
            platform: 'NEXT_GEN',
            metrics: [],
            properties: [],
        };
        removeEventListener('visibilitychange', listener);
        removeEventListener('pagehide', listener);
    },
};
export { bypassCommercialMetricsSampling, initCommercialMetrics };
