import { isUndefined } from '@guardian/libs';
import { breakpoints } from '@guardian/source-foundations';
import fastdom from 'utils/fastdom-promise';
/**
 * The minimum buffer between two adverts (aka winning paragraphs) in the right column
 */
const PARAGRAPH_BUFFER_PX = 600;
/**
 * The minimum buffer between a right column advert and an immersive element
 */
const IMMERSIVE_BUFFER_PX = 100;
/**
 * The minimum buffer between a right column advert and the bottom of the article body
 */
const ARTICLE_BOTTOM_BUFFER_PX = 100;
/**
 * The height of the labs header on paid content pages
 */
const LABS_HEADER_HEIGHT = 55;
/**
 * Add a stylesheet to the document that adds height properties for a given set of class names
 *
 * Note these are only above on desktop and above
 *
 * @param heightMapping The mapping from class name to height value in pixels
 */
const insertHeightStyles = (heightMapping) => {
    /**
     * Paid content has a banner at the top of the page. We don't want this
     * banner to overlap the advert. Adds extra padding for visual benefit
     */
    const top = window.guardian.config.page.isPaidContent
        ? `${LABS_HEADER_HEIGHT + 6}px`
        : 0;
    const heightClasses = heightMapping.reduce((css, [name, height]) => `${css} .${name} { min-height: ${height}px; } .${name} > * { position: sticky; top: ${top}; }`, '');
    const css = `
        @media (min-width: ${breakpoints.desktop}px) {
            ${heightClasses}
        }
    `;
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    return fastdom.mutate(() => {
        document.head.appendChild(style);
    });
};
/**
 * Compute the distance between each winning paragraph and subsequent paragraph,
 * taking into account elements that extend into the right column
 */
const computeStickyHeights = async (winners, articleBodySelector) => {
    // Immersive figures can extend into the right column
    // Therefore we have to take them into account when we can compute how far an ad can be sticky for
    const immersiveFigures = [
        ...document.querySelectorAll('[data-spacefinder-role="immersive"]'),
    ];
    const { figures, winningParas, articleBodyElementHeightBottom } = await fastdom.measure(() => {
        const figures = immersiveFigures.map((element) => ({
            kind: 'figure',
            top: element.getBoundingClientRect().top,
            element,
        }));
        const winningParas = winners.map((element) => ({
            kind: 'winningPara',
            top: element.getBoundingClientRect().top,
            element,
        }));
        const articleBodyElementHeightBottom = document
            .querySelector(articleBodySelector)
            ?.getBoundingClientRect().bottom ?? 0;
        return { figures, winningParas, articleBodyElementHeightBottom };
    });
    return (
    // Concat the set of figures and winning paragraphs in the article
    [...figures, ...winningParas]
        // Sort so they appear in order of their top coordinates
        .sort((first, second) => first.top - second.top)
        // Step through each one by one, measuring the height we can make the container of the ad slot
        .map((current, index, items) => {
        // We don't care about computing the distance *from* figures
        // These will be filtered out in the next step
        if (current.kind === 'figure') {
            return undefined;
        }
        // Retrieve the next element to which we'll extend the container height
        // This can be undefined if there is no next item
        const next = items[index + 1];
        // If there is no `next` element we've reached the final element in the article body
        // In this case we want to make the sticky distance extend until the bottom of the article body,
        // minus a small constant buffer
        if (next === undefined) {
            return (articleBodyElementHeightBottom -
                current.top -
                ARTICLE_BOTTOM_BUFFER_PX);
        }
        // Choose height of buffer depending on the kind of element we're measuring to
        const buffer = next.kind === 'winningPara'
            ? PARAGRAPH_BUFFER_PX
            : IMMERSIVE_BUFFER_PX;
        // Compute the distance from the top of the current element to the top of the next element, minus the buffer
        return Math.floor(next.top - current.top - buffer);
    })
        // Remove the figures marked as undefined
        // In effect keeping only the heights for winning paragraphs
        .filter((height) => !isUndefined(height)));
};
export { computeStickyHeights, insertHeightStyles };
