import { getCookie, log } from '@guardian/libs';
import { getCurrentBreakpoint } from 'lib/detect/detect-breakpoint';
import { adFreeDataIsPresent } from './manage-ad-free-cookie';
import userPrefs from './user-prefs';
/**
 * Log the reason why adverts are disabled
 *
 * @param trueConditions - normally true conditions, log if false
 * @param falseConditions - normally false conditions, log if true
 */
function adsDisabledLogger(trueConditions, falseConditions) {
    const noAdsLog = (condition, value) => log('commercial', `Adverts are not shown because ${condition} = ${String(value)}`);
    for (const [condition, value] of Object.entries(trueConditions)) {
        if (!value)
            noAdsLog(condition, value);
    }
    for (const [condition, value] of Object.entries(falseConditions)) {
        if (value)
            noAdsLog(condition, value);
    }
}
/**
 * Determine whether current browser is a version of Internet Explorer
 */
const isInternetExplorer = () => {
    return !!navigator.userAgent.match(/MSIE|Trident/g)?.length;
};
const DIGITAL_SUBSCRIBER_COOKIE = 'gu_digital_subscriber';
const isDigitalSubscriber = () => getCookie({ name: DIGITAL_SUBSCRIBER_COOKIE }) === 'true';
const isAdFreeUser = () => isDigitalSubscriber() || adFreeDataIsPresent();
// Having a constructor means we can easily re-instantiate the object in a test
class CommercialFeatures {
    shouldLoadGoogletag;
    isSecureContact;
    articleBodyAdverts;
    carrotTrafficDriver;
    highMerch;
    thirdPartyTags;
    commentAdverts;
    liveblogAdverts;
    adFree;
    comscore;
    launchpad;
    youtubeAdvertising;
    constructor() {
        // this is used for SpeedCurve tests
        const noadsUrl = /[#&]noads(&.*)?$/.test(window.location.hash);
        const forceAdFree = /[#&]noadsaf(&.*)?$/.test(window.location.hash);
        const forceAds = /[?&]forceads(&.*)?$/.test(window.location.search);
        const externalAdvertising = !noadsUrl && !userPrefs.isOff('adverts');
        const sensitiveContent = window.guardian.config.page.shouldHideAdverts ||
            window.guardian.config.page.section === 'childrens-books-site';
        const isMinuteArticle = window.guardian.config.page.isMinuteArticle;
        const isArticle = window.guardian.config.page.contentType === 'Article';
        const isInteractive = window.guardian.config.page.contentType === 'Interactive';
        const isLiveBlog = window.guardian.config.page.isLiveBlog;
        const isHosted = window.guardian.config.page.isHosted;
        const isIdentityPage = window.guardian.config.page.contentType === 'Identity' ||
            window.guardian.config.page.section === 'identity'; // needed for pages under profile.* subdomain
        const switches = window.guardian.config.switches;
        const isWidePage = getCurrentBreakpoint() === 'wide';
        const newRecipeDesign = window.guardian.config.page.showNewRecipeDesign;
        // TODO Convert detect.js to TypeScript
        const isUnsupportedBrowser = isInternetExplorer();
        this.isSecureContact = [
            'help/ng-interactive/2017/mar/17/contact-the-guardian-securely',
            'help/2016/sep/19/how-to-contact-the-guardian-securely',
        ].includes(window.guardian.config.page.pageId);
        // Feature switches
        this.adFree = !!forceAdFree || isAdFreeUser();
        this.youtubeAdvertising = !this.adFree && !sensitiveContent;
        const shouldLoadGoogletagTrueConditions = {
            'switches.shouldLoadGoogletag': !!switches.shouldLoadGoogletag,
            externalAdvertising,
        };
        const shouldLoadGoogletagFalseConditions = {
            sensitiveContent,
            isIdentityPage,
            adFree: this.adFree,
            isUnsupportedBrowser,
        };
        this.shouldLoadGoogletag =
            forceAds ||
                (Object.values(shouldLoadGoogletagTrueConditions).every(Boolean) &&
                    !Object.values(shouldLoadGoogletagFalseConditions).some(Boolean));
        if (!this.shouldLoadGoogletag) {
            adsDisabledLogger(shouldLoadGoogletagTrueConditions, shouldLoadGoogletagFalseConditions);
        }
        const articleBodyAdvertsTrueConditions = {
            isArticle,
        };
        const articleBodyAdvertsFalseConditions = {
            isMinuteArticle,
            isLiveBlog: !!isLiveBlog,
            isHosted,
            newRecipeDesign: !!newRecipeDesign,
        };
        this.articleBodyAdverts =
            this.shouldLoadGoogletag &&
                !this.adFree &&
                Object.values(articleBodyAdvertsTrueConditions).every(Boolean) &&
                !Object.values(articleBodyAdvertsFalseConditions).some(Boolean);
        if (isArticle && !this.articleBodyAdverts) {
            // Log why article adverts are disabled
            adsDisabledLogger(articleBodyAdvertsTrueConditions, articleBodyAdvertsFalseConditions);
        }
        this.carrotTrafficDriver =
            !this.adFree &&
                this.articleBodyAdverts &&
                !window.guardian.config.page.isPaidContent;
        this.highMerch =
            this.shouldLoadGoogletag &&
                !this.adFree &&
                !isMinuteArticle &&
                !isHosted &&
                !isInteractive &&
                !window.guardian.config.page.isFront &&
                !window.guardian.config.isDotcomRendering &&
                !newRecipeDesign;
        this.thirdPartyTags =
            !this.adFree &&
                externalAdvertising &&
                !isIdentityPage &&
                !this.isSecureContact;
        this.launchpad =
            !this.adFree &&
                externalAdvertising &&
                !isIdentityPage &&
                !this.isSecureContact &&
                !!window.guardian.config.switches.redplanetForAus;
        this.commentAdverts =
            this.shouldLoadGoogletag &&
                !this.adFree &&
                !isMinuteArticle &&
                !!window.guardian.config.switches.enableDiscussionSwitch &&
                window.guardian.config.page.commentable &&
                (!isLiveBlog || isWidePage);
        this.liveblogAdverts =
            !!isLiveBlog && this.shouldLoadGoogletag && !this.adFree;
        this.comscore =
            !!window.guardian.config.switches.comscore &&
                !isIdentityPage &&
                !this.isSecureContact;
    }
}
export const commercialFeatures = new CommercialFeatures();
