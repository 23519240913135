import { removeCookie, setCookie } from '@guardian/libs';
import { getUrlVars } from '../../utils/url';
/**
 * Not to be confused with set-adtest-cookie.ts!
 * Set or remove `adtestInLabels` cookie
 * This is used when determining whether or not to display the value of the `adtest` cookie in ad labels
 * @returns Promise
 */
const init = () => {
    const queryParams = getUrlVars();
    if (queryParams.adtestInLabels === 'clear') {
        removeCookie({ name: 'adtestInLabels' });
    }
    else if (queryParams.adtestInLabels) {
        setCookie({
            name: 'adtestInLabels',
            value: 'true',
        });
    }
    return Promise.resolve();
};
export { init };
