import { log } from '@guardian/libs';
import { once } from 'lodash-es';
import { requestBidsForAd } from 'display/request-bids';
import { getCurrentBreakpoint } from 'lib/detect/detect-breakpoint';
import { dfpEnv } from '../lib/dfp/dfp-env';
import { getAdvertById } from '../lib/dfp/get-advert-by-id';
import { loadAdvert, refreshAdvert } from './load-advert';
const displayAd = (advertId) => {
    const advert = getAdvertById(advertId);
    if (advert) {
        if (advert.isRendered) {
            refreshAdvert(advert);
        }
        else {
            loadAdvert(advert);
        }
    }
};
const requestBids = (advertId) => {
    const advert = getAdvertById(advertId);
    if (advert) {
        void requestBidsForAd(advert);
    }
};
const onIntersectDisplayAd = (entries, observer) => {
    const advertIds = [];
    entries
        .filter((entry) => !('isIntersecting' in entry) || entry.isIntersecting)
        .forEach((entry) => {
        log('commercial', 'display observer triggered for: ', entry.target.id);
        observer.unobserve(entry.target);
        displayAd(entry.target.id);
        advertIds.push(entry.target.id);
    });
    dfpEnv.advertsToLoad = dfpEnv.advertsToLoad.filter((advert) => !advertIds.includes(advert.id));
};
const onIntersectPrebid = (entries, observer) => {
    const advertIds = [];
    entries
        .filter((entry) => !('isIntersecting' in entry) || entry.isIntersecting)
        .forEach((entry) => {
        log('commercial', 'prebid observer triggered for: ', entry.target.id);
        observer.unobserve(entry.target);
        requestBids(entry.target.id);
        advertIds.push(entry.target.id);
    });
};
const getDisplayAdObserver = once((isEagerPrebid) => {
    return new window.IntersectionObserver(onIntersectDisplayAd, {
        rootMargin: isEagerPrebid ? '10% 0px' : '20% 0px',
    });
});
const getPrebidObserver = once(() => {
    return new window.IntersectionObserver(onIntersectPrebid, {
        rootMargin: '50% 0px',
    });
});
/**
 * Only load Prebid eagerly on desktop and above
 */
const shouldRunEagerPrebid = () => ['desktop', 'wide'].includes(getCurrentBreakpoint());
export const enableLazyLoad = (advert) => {
    if (dfpEnv.lazyLoadObserve) {
        const isEagerPrebid = shouldRunEagerPrebid();
        getDisplayAdObserver(isEagerPrebid).observe(advert.node);
        if (isEagerPrebid) {
            getPrebidObserver().observe(advert.node);
        }
    }
    else {
        displayAd(advert.id);
    }
};
