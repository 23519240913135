import ophan from '@guardian/ophan-tracker-js';
import { noop } from 'utils/noop';
import { reportError } from 'utils/report-error';
const not = (f) => (...args) => !f(...args);
const submit = (payload) => void ophan.record({
    abTestRegister: payload,
});
/**
 * generate an A/B event for ophan
 */
const makeABEvent = (variant, complete) => {
    const event = {
        variantName: variant.id,
        complete,
    };
    if (variant.campaignCode) {
        event.campaignCodes = [variant.campaignCode];
    }
    return event;
};
/**
 * Checks if this test will defer its impression by providing its own impression function.
 *
 * If it does, the test won't be included in the Ophan call that happens at pageload, and must fire the impression
 * itself via the callback passed to the `impression` property in the variant.
 */
const defersImpression = (test) => test.variants.every((variant) => typeof variant.impression === 'function');
/**
 * Create a function that will fire an A/B test to Ophan
 */
const buildOphanSubmitter = (test, variant, complete) => {
    const data = {
        [test.id]: makeABEvent(variant, String(complete)),
    };
    return () => submit(data);
};
/**
 * Create a function that sets up listener to fire an Ophan `complete` event. This is used in the `success` and
 * `impression` properties of test variants to allow test authors to control when these events are sent out.
 *
 * @see {@link defersImpression}
 */
const registerCompleteEvent = (complete) => (test) => {
    const variant = test.variantToRun;
    const listener = (complete ? variant.success : variant.impression) ?? noop;
    try {
        listener(buildOphanSubmitter(test, variant, complete));
    }
    catch (err) {
        reportError(err, {}, false);
    }
};
const registerCompleteEvents = (tests) => tests.forEach(registerCompleteEvent(true));
const registerImpressionEvents = (tests) => tests.filter(defersImpression).forEach(registerCompleteEvent(false));
const buildOphanPayload = (tests) => {
    try {
        const testsConfig = window.guardian.config.tests ?? {};
        const log = {};
        const serverSideTests = Object.keys(testsConfig).filter((test) => test in testsConfig);
        tests.filter(not(defersImpression)).forEach((test) => {
            log[test.id] = makeABEvent(test.variantToRun, 'false');
        });
        serverSideTests.forEach((test) => {
            const serverSideVariant = {
                id: 'inTest',
                test: () => undefined,
            };
            log[`ab${test}`] = makeABEvent(serverSideVariant, 'false');
        });
        return log;
    }
    catch (error) {
        // Encountering an error should invalidate the logging process.
        reportError(error, {}, false);
        return {};
    }
};
export const trackABTests = (tests) => submit(buildOphanPayload(tests));
export { registerCompleteEvents, registerImpressionEvents };
