import raven from 'lib/raven';
import { onSlotLoad } from '../../events/on-slot-load';
import { onSlotRender } from '../../events/on-slot-render';
import { onSlotViewableFunction } from '../../events/on-slot-viewable';
const initDfpListeners = () => {
    window.googletag.cmd.push(() => {
        const pubads = window.googletag.pubads();
        pubads.addEventListener('slotRenderEnded', raven.wrap(onSlotRender));
        pubads.addEventListener('slotOnload', raven.wrap(onSlotLoad));
        pubads.addEventListener('impressionViewable', onSlotViewableFunction());
    });
    return Promise.resolve();
};
export { initDfpListeners };
