import { findSpace, SpaceError } from 'insert/spacefinder/spacefinder';
import raven from 'lib/raven';
class SpaceFiller {
    queue = Promise.resolve(true);
    /**
     * A safer way of using spacefinder.
     * Given a set of spacefinder rules, applies a writer to the first matching paragraph.
     * Uses fastdom to avoid layout-thrashing, but queues up asynchronous writes to avoid race conditions. We don't
     * seek a slot for a new component until all the other component writes have finished.
     */
    fillSpace(rules, writer, options) {
        const insertNextContent = () => findSpace(rules, options)
            .then((paragraphs) => writer(paragraphs))
            .then(() => {
            return true;
        })
            .catch((ex) => {
            if (ex instanceof SpaceError) {
                return false;
            }
            throw ex;
        });
        this.queue = this.queue.then(insertNextContent).catch((e) => {
            // e.g. if writer fails
            raven.captureException(e);
            return false;
        });
        return this.queue;
    }
}
const spaceFiller = new SpaceFiller();
export { spaceFiller };
