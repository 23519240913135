import { constructQuery } from '../lib/construct-query';
import { buildPageTargeting } from './build-page-targeting';
const disabledAds = { disableAds: true };
const buildAdsConfig = (cmpConsent, adUnit, customParams, clientSideParticipations, isSignedIn = false) => {
    const mergedCustomParams = {
        ...customParams,
        ...buildPageTargeting({
            adFree: false,
            clientSideParticipations,
            consentState: cmpConsent,
            isSignedIn: isSignedIn,
            youtube: true,
        }),
        // 19/04/2023 This is a temporary update to assist reporting for a YouTube IMA test
        yt_embed_ima: '0',
    };
    const defaultAdsConfig = {
        adTagParameters: {
            iu: adUnit,
            // TODO: Why are we double encoding? Following Frontend process for now
            cust_params: encodeURIComponent(constructQuery(mergedCustomParams)),
        },
    };
    if (cmpConsent.framework === 'ccpa' || cmpConsent.framework === 'aus') {
        return {
            ...defaultAdsConfig,
            restrictedDataProcessor: !cmpConsent.canTarget,
        };
    }
    if (cmpConsent.framework === 'tcfv2') {
        const tcfData = cmpConsent.tcfv2;
        if (tcfData) {
            const mergedAdTagParameters = {
                ...defaultAdsConfig.adTagParameters,
                cmpGdpr: tcfData.gdprApplies ? 1 : 0,
                cmpGvcd: tcfData.addtlConsent,
                cmpVcd: tcfData.tcString,
            };
            return {
                adTagParameters: mergedAdTagParameters,
                nonPersonalizedAd: !cmpConsent.canTarget,
            };
        }
    }
    // Shouldn't happen but handle if no matching framework
    return disabledAds;
};
const buildAdsConfigWithConsent = ({ adUnit, clientSideParticipations, consentState, customParams, isAdFreeUser, isSignedIn, }) => {
    if (isAdFreeUser) {
        return disabledAds;
    }
    return buildAdsConfig(consentState, adUnit, customParams, clientSideParticipations, isSignedIn);
};
export { buildAdsConfigWithConsent, disabledAds };
