import { log } from '@guardian/libs';
import { once } from 'lodash-es';
import { dfpEnv } from '../../lib/dfp/dfp-env';
import fastdom from '../../utils/fastdom-promise';
// Remove ad slots
const selectors = [dfpEnv.adSlotSelector, '.top-banner-ad-container'];
const selectNodes = () => selectors.reduce((nodes, selector) => [
    ...nodes,
    ...Array.from(document.querySelectorAll(selector)),
], []);
const isDisabled = (node) => window.getComputedStyle(node).display === 'none';
const filterDisabledNodes = (nodes) => nodes.filter(isDisabled);
const removeNodes = (nodes) => fastdom.mutate(() => nodes.forEach((node) => {
    log('commercial', `Removing ad slot: ${node.id}`);
    node.remove();
}));
const removeSlots = () => {
    return removeNodes(selectNodes());
};
/**
 * Remove ad slot elements that have style display: none
 */
const removeDisabledSlots = once(() => removeNodes(filterDisabledNodes(selectNodes())));
export { removeSlots, removeDisabledSlots };
